import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import type { ReadonlyURLSearchParams } from 'next/navigation'
import querystring from 'querystring'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useAnalytics } from '~/components/hooks/useAnalytics'
import { useDeviceDetect } from '~/components/hooks/useDeviceDetect'
import { useCart, type CartContextInterface } from '~/components/providers/CartProvider/CartProvider'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'
import type {
	CheckEligibilityForLoyaltyQuery,
	ContentAssetsQuery,
	EnrollCustomerIntoLoyaltyMutation,
	GenerateUacfIdWalletPassMutation,
	GenerateUacfIdWalletPassPayload,
	GetAvailableRewardsQuery,
	GetCategoryPageQuery,
	GetLoyaltyCartDataQuery,
	GetLoyaltyPointDataQuery,
	JoinLoyaltyWaitListMutation,
	LoyaltyAvailableReward,
	LoyaltyClaimedCoupon,
	LoyaltyClaimedRewardFilterType,
	LoyaltyCouponStatus,
	LoyaltyEvent,
	LoyaltyRewardFlowType,
	LoyaltyRewardGroup,
	LoyaltyRewardPage,
	RedeemDiscountCouponMutation,
	RewardsHistoryQueryQuery,
	UnenrollCustomerFromLoyaltyMutation,
	WalletDevicePlatform,
} from '~/graphql/generated/uacapi/type-document-node'
import {
	CheckEligibilityForLoyaltyDocument,
	ContentAssetsDocument,
	CustomerAuthType,
	EnrollCustomerIntoLoyaltyDocument,
	GenerateUacfIdWalletPassDocument,
	GetAvailableRewardsDocument,
	GetCategoryPageDocument,
	GetLoyaltyCartDataDocument,
	GetLoyaltyPointDataDocument,
	JoinLoyaltyWaitListDocument,
	LoyaltyApiChannel,
	LoyaltyJoinWaitListStatus,
	LoyaltyStatus,
	RedeemDiscountCouponDocument,
	RewardsHistoryQueryDocument,
	UnenrollCustomerFromLoyaltyDocument,
} from '~/graphql/generated/uacapi/type-document-node'
import type { UserSessionInfo } from '~/lib/client-only/auth/types'
import { mapEnrollmentSource } from '~/lib/client-server/enrollment-helpers'
import { createClientLogger } from '~/lib/logger'
import { getProductDetail } from '~/lib/products'
import { EnrollmentSource, type LoyaltyRewardsData } from '~/lib/types/loyalty.interface'
import type { ClientProductDetail } from '~/lib/types/product.interface'
import { firstStringValue } from '~/lib/utils'

const logger = createClientLogger('LoyaltyProvider')

// TODO: these should not use graphql types
export interface LoyaltyContextInterface {
	loyalty?: LoyaltyRewardsData
	loyaltyOperationError?: Error
	loyaltyOperationLoading: boolean
	unenrolled: boolean
	createWalletPass: ({
		platform,
	}: {
		platform: WalletDevicePlatform
	}) => Promise<GenerateUacfIdWalletPassPayload | undefined>
	enroll: (_: {
		readonlySearchParams: ReadonlyURLSearchParams
		session?: UserSessionInfo
		source: EnrollmentSource
	}) => Promise<{ status: LoyaltyStatus; success: boolean }>
	getAvailableRewards: ({
		display,
		flows,
	}: {
		display: LoyaltyRewardPage[]
		flows: LoyaltyRewardFlowType[]
	}) => Promise<LoyaltyRewardGroup[]>
	getRewardAssets: <T>(_: {
		reward: LoyaltyAvailableReward
		mapper: (_: { id?: string; body?: string }) => T
	}) => Promise<T[] | undefined>
	getRewardProduct: (_: { reward: LoyaltyAvailableReward }) => Promise<ClientProductDetail | undefined>
	getRewardUrl: (_: { reward: LoyaltyAvailableReward }) => Promise<string | undefined>
	getRewardsHistory: (input?: { count: number; page: number }) => Promise<LoyaltyEvent[]>
	isEligible: ({ postal, region }: { postal: string; region: string }) => Promise<boolean>
	joinWaitlist: ({ email, postal }: { email: string; postal: string }) => Promise<boolean>
	redeemCoupon: ({ id }: { id: number }) => Promise<boolean>
	redeemReward: (input: Parameters<CartContextInterface['redeemReward']>[0]) => Promise<boolean>
	refresh: ({
		claimed: { filter, status },
		display,
		flow,
	}: {
		claimed: { filter: LoyaltyClaimedRewardFilterType; status: LoyaltyCouponStatus }
		display: LoyaltyRewardPage[]
		flow: LoyaltyRewardFlowType
	}) => Promise<LoyaltyRewardsData | undefined>
	unenroll: () => Promise<boolean>
}

export const defaultLoyaltyProvider: LoyaltyContextInterface = {
	loyaltyOperationLoading: false,
	unenrolled: false,
	createWalletPass: async () => {
		throw new Error('createWalletPass() not implemented. Please include LoyaltyProvider in your app.')
	},
	enroll: async () => {
		throw new Error('enroll() not implemented. Please include LoyaltyProvider in your app.')
	},
	getAvailableRewards: async () => {
		throw new Error('getAvailableRewards() not implemented. Please include LoyaltyProvider in your app.')
	},
	getRewardAssets: async () => {
		throw new Error('getRewardAssets() not implemented. Please include LoyaltyProvider in your app.')
	},
	getRewardProduct: async () => {
		throw new Error('getRewardProduct() not implemented. Please include LoyaltyProvider in your app.')
	},
	getRewardUrl: async () => {
		throw new Error('getRewardUrl() not implemented. Please include LoyaltyProvider in your app.')
	},
	getRewardsHistory: async () => {
		throw new Error('getRewardsHistory() not implemented. Please include LoyaltyProvider in your app.')
	},
	isEligible: async () => {
		throw new Error('isEligible() not implemented. Please include LoyaltyProvider in your app.')
	},
	joinWaitlist: async () => {
		throw new Error('joinWaitlist() not implemented. Please include LoyaltyProvider in your app.')
	},
	redeemCoupon: async () => {
		throw new Error('redeemCoupon() not implemented. Please include LoyaltyProvider in your app.')
	},
	redeemReward: async () => {
		throw new Error('redeemReward() not implemented. Please include LoyaltyProvider in your app.')
	},
	refresh: async () => {
		throw new Error('refresh() not implemented. Please include LoyaltyProvider in your app.')
	},
	unenroll: async () => {
		throw new Error('unenroll() not implemented. Please include LoyaltyProvider in your app.')
	},
}

export const LoyaltyContext = createContext(defaultLoyaltyProvider)

export function useLoyalty() {
	return useContext(LoyaltyContext)
}

function LoyaltyProvider({ children }: React.PropsWithChildren): React.ReactElement {
	const { analyticsManager } = useAnalytics()
	const client = useApolloClient()
	const { cart, redeemReward: redeemRewardCart } = useCart()
	const { deviceType } = useDeviceDetect()
	const { user, refresh: refreshUser } = useSession()

	const [redeemRewardError, setRedeemRewardError] = useState<Error>()
	const [redeemRewardLoading, setRedeemRewardLoading] = useState<boolean>(false)
	const [unenrolled, setUnenrolled] = useState<boolean>(false)
	const [loyalty, setLoyalty] = useState<LoyaltyRewardsData>()

	// #region queries
	const [getAvailableRewardsQuery, { loading: getAvailableRewardsLoading, error: getAvailableRewardsError }] =
		useLazyQuery<GetAvailableRewardsQuery>(GetAvailableRewardsDocument, {
			fetchPolicy: 'no-cache',
			onError(error) {
				logger.error({ error }, 'getAvailableRewardsQuery')
			},
		})

	// TODO: truncate the query results?
	const [getCategoryPageQuery, { loading: getCategoryPageLoading, error: getCategoryPageError }] =
		useLazyQuery<GetCategoryPageQuery>(GetCategoryPageDocument, {
			onError(error) {
				logger.error({ error }, 'getCategoryPageQuery')
			},
		})

	// TODO: truncate the query results?
	const [getContentAssetQuery, { loading: getContentAssetLoading, error: getContentAssetError }] =
		useLazyQuery<ContentAssetsQuery>(ContentAssetsDocument, {
			onError(error) {
				logger.error({ error }, 'getAvailableRewardsQuery')
			},
		})

	const [getLoyaltyPointsQuery, { loading: getLoyaltyPointsLoading, error: getLoyaltyPointsError }] =
		useLazyQuery<GetLoyaltyPointDataQuery>(GetLoyaltyPointDataDocument, {
			fetchPolicy: 'no-cache',
			onError(error) {
				logger.error({ error }, 'getLoyaltyPointsQuery')
			},
		})

	const [isEligibleQuery, { loading: isEligibleLoading, error: isEligibleError }] =
		useLazyQuery<CheckEligibilityForLoyaltyQuery>(CheckEligibilityForLoyaltyDocument, {
			fetchPolicy: 'no-cache',
			onError(error) {
				logger.error({ error }, 'isEligibleQuery')
			},
		})

	const [getLoyaltyCartDataQuery, { loading: getLoyaltyCartDataLoading, error: getLoyaltyCartDataError }] =
		useLazyQuery<GetLoyaltyCartDataQuery>(GetLoyaltyCartDataDocument, {
			fetchPolicy: 'no-cache',
			onError(error) {
				logger.error({ error }, 'getLoyaltyCartDataQuery')
			},
		})

	const [rewardsHistoryQuery, { loading: rewardsHistoryLoading, error: rewardsHistoryError }] =
		useLazyQuery<RewardsHistoryQueryQuery>(RewardsHistoryQueryDocument, {
			fetchPolicy: 'no-cache',
			onError(error) {
				logger.error({ error }, 'rewardsHistoryQuery')
			},
		})
	// #endregion

	// #region mutations
	const [enrollMutation, { loading: enrollLoading, error: enrollError }] =
		useMutation<EnrollCustomerIntoLoyaltyMutation>(EnrollCustomerIntoLoyaltyDocument, {
			errorPolicy: 'none',
			onError(error) {
				logger.error({ error }, 'enrollMutation')
			},
		})

	const [generateWalletPassMutation, { loading: generateWalletPassLoading, error: generateWalletPassError }] =
		useMutation<GenerateUacfIdWalletPassMutation>(GenerateUacfIdWalletPassDocument, {
			errorPolicy: 'none',
			onError(error) {
				logger.error({ error }, 'generateWalletPassMutation')
			},
		})

	const [joinWaitlistMutation, { loading: joinWaitlistLoading, error: joinWaitlistError }] =
		useMutation<JoinLoyaltyWaitListMutation>(JoinLoyaltyWaitListDocument, {
			errorPolicy: 'none',
			onError(error) {
				logger.error({ error }, 'joinWaitlistMutation')
			},
		})

	const [redeemCouponMutation, { loading: redeemCouponLoading, error: redeemCouponError }] =
		useMutation<RedeemDiscountCouponMutation>(RedeemDiscountCouponDocument, {
			errorPolicy: 'none',
			onError(error) {
				logger.error({ error }, 'redeemCouponMutation')
			},
		})

	const [unenrollMutation, { loading: unenrollLoading, error: unenrollError }] =
		useMutation<UnenrollCustomerFromLoyaltyMutation>(UnenrollCustomerFromLoyaltyDocument, {
			errorPolicy: 'none',
			onError(error) {
				logger.error({ error }, 'unenrollMutation')
			},
		})
	// #endregion

	const refreshPoints = useCallback(async () => {
		if (user?.isLoyalty) {
			const { data } = await getLoyaltyPointsQuery()
			if (typeof data?.getLoyaltyPointsData.loyaltyPointsBalance !== 'undefined') {
				setLoyalty((prev) => ({
					claimedRewards: prev?.claimedRewards,
					customerPoints: data.getLoyaltyPointsData.loyaltyPointsBalance,
					rewardGroups: prev?.rewardGroups ?? [],
				}))
			}
		}
	}, [user, getLoyaltyPointsQuery])

	const createWalletPass = useCallback(
		async ({ platform }: { platform: WalletDevicePlatform }) => {
			const { data } = await generateWalletPassMutation({ variables: { platform } })
			return data?.generateUacfIdWalletPass
		},
		[generateWalletPassMutation],
	)

	const enroll = useCallback(
		async ({
			readonlySearchParams,
			session,
			source,
		}: {
			readonlySearchParams: ReadonlyURLSearchParams
			session?: UserSessionInfo
			source: EnrollmentSource
		}): Promise<{ status: LoyaltyStatus; success: boolean }> => {
			const { channel, subChannel, subChannelDetail } = querystring.parse(readonlySearchParams.toString())

			const resp = await enrollMutation({
				variables: {
					input: {
						channel: LoyaltyApiChannel[firstStringValue(channel)] || LoyaltyApiChannel.WEB,
						subChannel: firstStringValue(subChannel) || deviceType,
						subChannelDetail:
							firstStringValue(subChannelDetail) ||
							analyticsManager.getExternalCampaignData().external_campaign_id ||
							mapEnrollmentSource(source),
					},
				},
				...(session && {
					context: {
						headers: {
							authorization: `Bearer ${session?.uacapi?.accessToken}`,
						},
					},
				}),
			})

			const status = resp.data?.enrollCustomerIntoLoyalty.loyalty?.status ?? LoyaltyStatus.UNENROLLED

			if (resp.data?.enrollCustomerIntoLoyalty.success) {
				if (status === LoyaltyStatus.ENROLLED) {
					analyticsManager.fireLoyaltyAction({
						loyalty: {
							loyalty: true,
							action: 'subscribe',
							source,
						},
					})

					const currentSession = session ?? user?.session

					if (currentSession?.accountType === CustomerAuthType.REGISTERED) {
						// In the case of loyalty enrollment post registration or login,
						// this will do the work of updating the global user object
						// and ensuring that client storage is updated as well.
						await refreshUser(currentSession)
					}
				}

				return {
					status,
					success: true,
				}
			}

			logger.error(resp.data?.enrollCustomerIntoLoyalty.messages[0].message, 'enroll() error')

			return { status, success: false }
		},
		[analyticsManager, deviceType, user, enrollMutation, refreshUser],
	)

	const getAvailableRewards = useCallback(
		async ({
			display,
			flows,
		}: {
			display: LoyaltyRewardPage[]
			flows: LoyaltyRewardFlowType[]
		}): Promise<LoyaltyRewardGroup[]> => {
			const { data } = await getAvailableRewardsQuery({
				variables: {
					// customerNo: user?.profile?.uacfId ?? '',
					customerNo: '',
					displayOnPages: display,
					rewardFlowTypes: flows,
				},
			})

			return data?.availableRewardsToClaim.rewardGroups.filter((rg): rg is LoyaltyRewardGroup => !!rg) ?? []
		},
		[getAvailableRewardsQuery],
	)

	const getRewardAssets = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
		async <T extends unknown>({
			reward,
			mapper,
		}: {
			reward: LoyaltyAvailableReward
			mapper: (_: { id?: string; body?: string }) => T
		}): Promise<T[] | undefined> => {
			if (!reward?.ctaURL) {
				return undefined
			}

			const { data } = await getContentAssetQuery({
				variables: { ids: reward.ctaURL },
			})

			return data?.contentAssets
				?.map((a) => ({ ...a, body: a?.body ?? undefined }))
				.map(mapper)
				.filter((a) => !!a)
		},
		[getContentAssetQuery],
	)

	const getRewardProduct = useCallback(
		async ({ reward }: { reward: LoyaltyAvailableReward }): Promise<ClientProductDetail | undefined> => {
			if (!reward?.productID) {
				return undefined
			}
			try {
				// returning the awaited promise here, so we can handle a possible thrown error below
				return await getProductDetail(client, reward.productID)
			} catch (error) {
				logger.error({ error }, 'getRewardProduct')
				return undefined
			}
		},
		[client],
	)

	const getRewardUrl = useCallback(
		async ({ reward }: { reward: LoyaltyAvailableReward }) => {
			if (!reward?.ctaURL) {
				return undefined
			}

			const { data } = await getCategoryPageQuery({
				variables: { id: reward.ctaURL },
			})

			return data?.category?.url ?? undefined
		},
		[getCategoryPageQuery],
	)

	const getRewardsHistory = useCallback(
		async ({ count, page }: { count: number; page: number } = { count: 100, page: 1 }): Promise<LoyaltyEvent[]> => {
			const { data } = await rewardsHistoryQuery({
				variables: {
					// customerNo: user?.profile?.uacfId ?? '',
					customerNo: '',
					pageNumber: page,
					pageSize: count,
				},
			})

			return data?.getLoyaltyPointsData.loyaltyEvents ?? []
		},
		[rewardsHistoryQuery],
	)

	const isEligible = useCallback(
		async ({ postal, region }: { postal: string; region: string }) => {
			const { data } = await isEligibleQuery({ variables: { input: { postalCode: postal, region } } })
			return data?.checkEligibilityForLoyalty.eligible ?? false
		},
		[isEligibleQuery],
	)

	const joinWaitlist = useCallback(
		async ({ email, postal }: { email: string; postal: string }): Promise<boolean> => {
			const { data } = await joinWaitlistMutation({
				variables: {
					input: {
						email,
						postalCode: postal,
					},
				},
			})
			return data?.joinLoyaltyWaitList.status === LoyaltyJoinWaitListStatus.SUCCESS
		},
		[joinWaitlistMutation],
	)

	const redeemCoupon = useCallback(
		async ({ id }: { id: number }) => {
			if (user?.isLoyalty) {
				const resp = await redeemCouponMutation({
					variables: {
						LoyaltyClaimDiscountCouponInput: {
							rewardId: id,
						},
					},
				})

				if (resp.data?.claimDiscountCouponWithRewardPoints.success) {
					await refreshPoints()
					return true
				}
			}

			return false
		},
		[user, redeemCouponMutation, refreshPoints],
	)

	const redeemReward = useCallback(
		async (input: Parameters<CartContextInterface['redeemReward']>[0]) => {
			if (user?.isLoyalty) {
				setRedeemRewardError(undefined)
				setRedeemRewardLoading(true)
				const resp = await redeemRewardCart(input)
				if (resp.success) {
					await refreshPoints()
				} else {
					setRedeemRewardError(resp.errors.find((e) => e.error)?.error)
				}
				setRedeemRewardLoading(false)
				return resp.success
			}
			return false
		},
		[user, redeemRewardCart, refreshPoints],
	)

	const refresh = useCallback(
		async ({
			claimed: { filter, status },
			display,
			flow,
		}: {
			claimed: { filter: LoyaltyClaimedRewardFilterType; status: LoyaltyCouponStatus }
			display: LoyaltyRewardPage[]
			flow: LoyaltyRewardFlowType
		}): Promise<LoyaltyRewardsData | undefined> => {
			const { data } = await getLoyaltyCartDataQuery({
				variables: {
					// customerNo: user?.profile?.uacfId ?? '',
					customerNo: '',
					displayOnPages: display,
					loyaltyClaimedRewardsInput: {
						// customerNo: user?.profile?.uacfId ?? '',
						customerNo: '',
						filterParam: status,
						filterType: filter,
					},
					rewardFlowTypes: flow,
				},
			})

			let loyalty: LoyaltyRewardsData | undefined

			if (data) {
				const coupons = cart?.couponItems?.map((item) => item.code) ?? []
				const claimed = data.claimedRewards.coupon
					.filter((c): c is LoyaltyClaimedCoupon => !!c)
					.filter((c) => coupons.includes(c.code as string))
				const points = data.getLoyaltyPointsData.loyaltyPointsBalance
				const groups = data.availableRewardsToClaim.rewardGroups.filter((rg): rg is LoyaltyRewardGroup => !!rg)

				loyalty = { claimedRewards: claimed, customerPoints: points, rewardGroups: groups }
				setLoyalty(loyalty)
			}

			return loyalty
		},
		[cart, getLoyaltyCartDataQuery],
	)

	const unenroll = useCallback(async () => {
		if (user?.isLoyalty) {
			const resp = await unenrollMutation({
				variables: {
					input: {
						customerNo: '',
						// customerNo: user?.profile?.uacfId ?? '',
					},
				},
			})

			if (resp.data) {
				analyticsManager.fireLoyaltyAction({
					loyalty: {
						loyalty: true,
						action: 'unsubscribe',
						source: EnrollmentSource.ECOMMUNENROLLREWARDS,
					},
				})

				const currentSession = user?.session
				if (currentSession) {
					await refreshUser(currentSession)
				}

				setUnenrolled(true)
				return true
			}
		}

		return false
	}, [analyticsManager, user, unenrollMutation, refreshUser])

	useEffect(() => {
		refreshPoints()
		// TODO: does this trigger multiple?
	}, [user, refreshPoints])

	const loyaltyOperationError = useMemo(
		() =>
			enrollError ||
			generateWalletPassError ||
			getAvailableRewardsError ||
			getCategoryPageError ||
			getContentAssetError ||
			getLoyaltyCartDataError ||
			getLoyaltyPointsError ||
			isEligibleError ||
			joinWaitlistError ||
			redeemCouponError ||
			redeemRewardError ||
			rewardsHistoryError ||
			unenrollError,
		[
			enrollError,
			generateWalletPassError,
			getAvailableRewardsError,
			getCategoryPageError,
			getContentAssetError,
			getLoyaltyCartDataError,
			getLoyaltyPointsError,
			isEligibleError,
			joinWaitlistError,
			redeemCouponError,
			redeemRewardError,
			rewardsHistoryError,
			unenrollError,
		],
	)

	const loyaltyOperationLoading = useMemo(
		() =>
			enrollLoading ||
			generateWalletPassLoading ||
			getAvailableRewardsLoading ||
			getCategoryPageLoading ||
			getContentAssetLoading ||
			getLoyaltyCartDataLoading ||
			getLoyaltyPointsLoading ||
			isEligibleLoading ||
			joinWaitlistLoading ||
			redeemCouponLoading ||
			redeemRewardLoading ||
			rewardsHistoryLoading ||
			unenrollLoading,
		[
			enrollLoading,
			generateWalletPassLoading,
			getAvailableRewardsLoading,
			getContentAssetLoading,
			getCategoryPageLoading,
			getLoyaltyCartDataLoading,
			getLoyaltyPointsLoading,
			isEligibleLoading,
			joinWaitlistLoading,
			redeemCouponLoading,
			redeemRewardLoading,
			rewardsHistoryLoading,
			unenrollLoading,
		],
	)

	const contextValue = useMemo(
		() => ({
			loyalty,
			loyaltyOperationError,
			loyaltyOperationLoading,
			createWalletPass,
			enroll,
			getAvailableRewards,
			getRewardAssets,
			getRewardProduct,
			getRewardUrl,
			getRewardsHistory,
			isEligible,
			joinWaitlist,
			redeemCoupon,
			redeemReward,
			refresh,
			unenroll,
			unenrolled,
		}),
		[
			loyalty,
			loyaltyOperationError,
			loyaltyOperationLoading,
			createWalletPass,
			enroll,
			getAvailableRewards,
			getRewardAssets,
			getRewardProduct,
			getRewardUrl,
			getRewardsHistory,
			isEligible,
			joinWaitlist,
			redeemCoupon,
			redeemReward,
			refresh,
			unenroll,
			unenrolled,
		],
	)

	return <LoyaltyContext.Provider value={contextValue}>{children}</LoyaltyContext.Provider>
}

export default LoyaltyProvider
